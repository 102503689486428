import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class EmpresaService {
  async getFirstEmpresa(){
    const empresa = await fetchWrapper.get(`${ruta}/empresa`);
    return empresa;
  }

  async updateEmpresaLogo(empresa_id, empresa){
    const empresaActualizada = await fetchWrapper.postFiles(`${ruta}/empresa_logo/`+empresa_id, empresa);
    return empresaActualizada;
  }

  async updateEmpresa(empresa_id, empresa){
    const empresaActualizada = await fetchWrapper.put(`${ruta}/empresa/`+empresa_id, empresa);
    return empresaActualizada;
  }
}